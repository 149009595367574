<template>
  <div class="content">
    <v-row justify="center">
      <div v-if="procensando_datos">
        <loader
          object="#4caf50"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="40"
          disableScrolling="false"
          name="box"
        ></loader>
      </div>
      <!-- MODAL DIALOG FORMULARIO PARA CREAR NUEVO CLIENTE -->
      <v-dialog v-model="createCliente" persistent max-width="600px">
        <!-- boton nuevo cliente  -->
        <template v-slot:activator="{ on, attrs }">
          <div class="col-md-12" align="right">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="mb-4"
              v-on="on"
              @click="abrir_create_cliente()"
            >
              Nuevo cliente
            </v-btn>
          </div>
        </template>
        <!-- end boton nuevo cliente  -->
        <v-card>
          <v-card-title>
            <span class="text-h5">Nuevo cliente</span>
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cerrar_create_cliente()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="cuit"
                  :counter="11"
                  :rules="namecuit"
                  label="CUIT"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="razon_social"
                  :counter="250"
                  :rules="namerazon_social"
                  label="Razon social"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="cliente"
                  :counter="30"
                  :rules="namecliente"
                  label="Cliente"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="nombre_fantasia"
                  :counter="30"
                  :rules="namenombre_fantasia"
                  label="Nombre fantasia"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  :rules="nameemail"
                  label="Email"
                  required
                ></v-text-field>

                <v-select
                  v-model="selectivaiva"
                  :items="iva"
                  item-text="porcentaje"
                  item-value="id"
                  :rules="[(v) => !!v || 'IVA es requerido']"
                  label="Seleccionar Iva"
                  required
                ></v-select>

                <v-select
                  v-model="selectivaiibb"
                  :items="iibb"
                  item-text="porcentaje"
                  item-value="id"
                  :rules="[(v) => !!v || 'IIBB es requerido']"
                  label="Seleccionar Ingresos Brutos"
                  required
                ></v-select>

                <v-select
                  v-model="personeria"
                  :items="items"
                  item-text="personeria"
                  :item-value="'id'"
                  :rules="[(v) => !!v || 'Personeria es requerido']"
                  label="Personeria"
                  required
                ></v-select>

                <!-- Reporte por Cliente o por Cuenta -->
                <v-select
                  v-model="Reporte_Cliente_Cuenta"
                  :items="ReporteItem"
                  @change="ReporteSeleccionado(Reporte_Cliente_Cuenta)"
                  label="Reportes"
                  required
                ></v-select>

                <!-- Seleccionar Clientes para vincular -->
                <!-- <v-select
                  v-model="cliente_vinculo"
                  :items="filteredClientesSplit0"
                  item-text="cliente"
                  item-value="id"
                  :rules="clienteVinculoRules"
                  label="Clientes split cuentas"
                  v-show="split_cuentas"
                >
                </v-select> -->

                <!-- activar retiros_automaticos -->
                <v-row>
                  <v-col cols="12" sm="6" class="d-flex align-center my-n4">
                    <v-checkbox
                      v-model="retiro_automatico"
                      class="ckeck"
                      label="Retiros automáticos"
                    ></v-checkbox>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" class="d-flex align-center my-n4">
                    <v-checkbox
                      v-model="split_cuentas"
                      class="ckeck"
                      label="Split cuentas"
                    ></v-checkbox>
                  </v-col> -->
                  <v-col cols="12" sm="6" class="d-flex align-center my-n4">
                    <v-checkbox
                      v-model="sujeto_retencion"
                      class="ckeck"
                      label="Sujeto de retención"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex align-center my-n4">
                    <v-checkbox
                      v-model="formulario_8125"
                      class="ckeck"
                      label="Formulario 8125"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar_create_cliente()">
              Cerrar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="dialog"
              color="blue darken-1"
              text
              @click="guardar_cliente()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END MODAL DIALOG FORMULARIO PARA CREAR NUEVO CLIENTE -->
    </v-row>

    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Clientes</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde. Volver a
                intentar
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="clienteRSNF"
                  append-icon="mdi-magnify"
                  label="Buscar Cliente"
                  single-line
                  hide-details
                  clearable
                >
                </v-text-field>
              </v-card-title>

              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :items-per-page="rowsPerPage"
                hide-default-footer
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-0 trow"
              >
                <template v-slot:[`item.retiro_automatico`]="{ item }">
                  <v-chip :color="getColor(item.retiro_automatico)" dark>
                    {{ retiros_automaticos(item.retiro_automatico) }}
                  </v-chip>
                </template>
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar cliente  -->
                    <v-dialog v-model="dialog" max-width="600px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar cliente</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>

                        <v-form ref="formEdit" v-model="valid" lazy-validation>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="cuitedit"
                                    label="CUIT"
                                    :counter="11"
                                    :rules="namecuit"
                                    required
                                    @input="() => (bandera = true)"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="razon_socialedit"
                                    label="Razon social"
                                    :counter="250"
                                    :rules="namerazon_social"
                                    required
                                    @input="() => (bandera = true)"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="clienteedit"
                                    :counter="30"
                                    :rules="namecliente"
                                    label="Cliente"
                                    required
                                    @input="() => (bandera = true)"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="nombre_fantasiaedit"
                                    :counter="30"
                                    :rules="namenombre_fantasia"
                                    label="Nombre fantasia"
                                    required
                                    @input="() => (bandera = true)"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="email_edit"
                                    :rules="nameemail"
                                    label="Email"
                                    required
                                    @input="() => (bandera = true)"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-select
                                    v-model="iva_idedit"
                                    :items="iva"
                                    item-value="id"
                                    item-text="porcentaje"
                                    :rules="[(v) => !!v || 'IVA es requerido']"
                                    label="Seleccionar Iva"
                                    return-object
                                    required
                                    @change="() => (bandera = true)"
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-select
                                    v-model="iibb_idedit"
                                    :items="iibb"
                                    item-value="id"
                                    item-text="porcentaje"
                                    :rules="[(v) => !!v || 'IIBB es requerido']"
                                    label="Seleccionar Ingresos Brutos"
                                    return-object
                                    required
                                    @change="() => (bandera = true)"
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <!-- campos con select  -->
                                  <v-select
                                    v-model="personeriaedit"
                                    :items="items"
                                    item-text="personeria"
                                    :item-value="'id'"
                                    :rules="[
                                      (v) => !!v || 'Personeria es requerido',
                                    ]"
                                    @change="() => (bandera = true)"
                                    label="Personeria"
                                    required
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <!-- Reporte por Cliente o por Cuenta Edit -->
                                  <v-select
                                    v-model="Reporte_Cliente_Cuenta_edit"
                                    :items="ReporteItem"
                                    @change="
                                      () => {
                                        ReporteSeleccionado(
                                          Reporte_Cliente_Cuenta_edit
                                        );
                                        bandera = true;
                                      }
                                    "
                                    label="Reportes"
                                    required
                                  ></v-select>
                                </v-col>

                                <!-- Seleccionar Clientes para Split Cuenta EDITAR Split-->
                                <!-- <v-col cols="12" sm="12" md="12">
                                  <v-select
                                    v-model="cliente_vinculo"
                                    :items="filteredClientes"
                                    :rules="clienteVinculoRules2"
                                    item-text="cliente"
                                    item-value="id"
                                    label="Clientes split cuentas"
                                    @change="
                                      () => {
                                        getCliente, (bandera = true);
                                      }
                                    "
                                    v-if="split_cuentas_edit"
                                  >
                                  </v-select>
                                </v-col> -->

                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-center my-n4"
                                  >
                                    <!-- activar retiros_automaticos -->
                                    <v-checkbox
                                      v-model="retiro_automatico_edit"
                                      class="ckeck"
                                      label="Retiros automáticos"
                                      @change="() => (bandera = true)"
                                    ></v-checkbox>
                                  </v-col>
                                  <!-- <v-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-center my-n4"
                                  > -->
                                  <!-- activar split_cuentas para Editar Split -->
                                  <!-- <v-checkbox
                                      v-model="split_cuentas_edit"
                                      class="ckeck"
                                      label="Split cuentas"
                                      @change="() => (bandera = true)"
                                    ></v-checkbox> -->
                                  <!-- </v-col> -->
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-center my-n4"
                                  >
                                    <!-- activar sujeto_retencion -->
                                    <v-checkbox
                                      v-model="sujeto_retencion_edit"
                                      class="ckeck"
                                      label="Sujeto de retención"
                                      @change="() => (bandera = true)"
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-center my-n4"
                                  >
                                    <!-- activar formulario_8125 -->
                                    <v-checkbox
                                      v-model="formulario_8125_edit"
                                      class="ckeck"
                                      label="Formulario 8125"
                                      @change="() => (bandera = true)"
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>

                                <!-- <v-row>
                                  <v-checkbox
                                    v-model="retiro_automatico_edit"
                                    class="ckeck"
                                    label="Retiros automaticos"
                                    style="margin-right: 100px; margin-left: 10px;"
                                  ></v-checkbox>
                                  

                                  <v-checkbox
                                    v-model="split_cuentas_edit"
                                    class="ckeck"
                                    label="Split cuentas"
                                  ></v-checkbox>
                                  
                                  <v-checkbox
                                    v-model="sujeto_retencion_edit"
                                    class="ckeck"
                                    label="Sujeto de retencion"
                                    style="margin-right: 100px; margin-left: 10px;"
                                  ></v-checkbox>
                                 
                                  <v-checkbox
                                    v-model="formulario_8125_edit"
                                    class="ckeck"
                                    label="Formulario 8125"
                                  ></v-checkbox>
                                </v-row> -->
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid || !bandera"
                              color="blue darken-1"
                              text
                              @click="actualizar"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!-- modal eliminar -->
                    <!-- modal contactos -->
                    <DialogContactos ref="dialogContactos" />
                  </v-toolbar>
                </template>
                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <v-icon
                    color="green darken-2"
                    @click="cargar_cuentas_cliente(item)"
                  >
                    visibility
                  </v-icon> -->
                  <!-- 1. ver las cuentas del cliente -->
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color=""
                        @click="cargar_cuentas_cliente(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> assignment_ind </v-icon>
                      </v-btn>
                    </template>
                    <span>Cuentas</span>
                  </v-tooltip>

                  <!-- 2. editar cliente  -->
                  <v-tooltip top color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="orange"
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> create </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar cliente</span>
                  </v-tooltip>
                  <!-- 3. Informacion de facturacion del cliente -->
                  <!-- <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="info"
                        @click="facturaciones(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> receipt_long </v-icon>
                      </v-btn>
                    </template>
                    <span>Facturación</span>
                  </v-tooltip> -->

                  <!-- <v-icon color="orange" @click="editItem(item)">
                    create
                  </v-icon> -->
                  <!-- 4. CONTACTOS DEL CLIENTE -->
                  <v-tooltip top color="yellow darken-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="yellow darken-2"
                        @click="showDialogContactos(item)"
                      >
                        <v-icon dark> mdi-notebook-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Contactos</span>
                  </v-tooltip>

                  <!-- 5. ENVIOS -->
                  <!-- <v-tooltip top color="teal darken-3">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="teal darken-3"
                        @click="showEnviosCliente(item)"
                      >
                        <v-icon dark>
                          mdi-file-send-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Reportes</span>
                  </v-tooltip> -->

                  <!-- 6. RETENCIONES DEL CLIENTE -->
                  <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        @click="navigateToRetencion(item.id)"
                        v-show="item.sujeto_retencion"
                      >
                        <v-icon dark> mdi-calculator </v-icon>
                      </v-btn>
                    </template>
                    <span>Retenciones</span>
                  </v-tooltip>
                </template>
                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
                <!--END  BOTONES DE LA TABLA -->
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
              <!-- v-model: enlaza el valor del número de página actual
            length: paginas totales -->
            </section>
          </div>
          <!-- /* mostrar informacion de facturacion  -->
          <v-row justify="center">
            <v-dialog v-model="facturacion" width="1000px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Facturación</span>
                </v-card-title>
                <v-card-text>
                  <div id="nav-tabs">
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <v-card>
                          <v-tabs vertical>
                            <v-tab>
                              <v-icon left> segment </v-icon>
                              Razon social
                            </v-tab>
                            <v-tab>
                              <v-icon left> paid </v-icon>
                              IVA - IIBB
                            </v-tab>
                            <v-tab>
                              <v-icon left> supervisor_account </v-icon>
                              Cuentas
                            </v-tab>
                            <!-- Razon social -->
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                  <!-- <div class="loader" v-if="loading"> </div>
                                  <div class="loader1" v-if="loading"> </div>
                                  <div class="loader2" v-if="loading"> </div> -->
                                  <table class="table">
                                    <tbody align="left">
                                      <tr>
                                        <td>
                                          <strong> Cliente</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="data.cliente"
                                          ></p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong> Razon social</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="data.razon_social"
                                          ></p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong> Nombre fantasia</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="data.nombre_fantasia"
                                          ></p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <!-- IVA IIBB -->
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                  <table class="table">
                                    <tbody align="left">
                                      <tr>
                                        <td><strong>IVA</strong></td>
                                        <td>
                                          Impuestos:
                                          {{ ivafacturacion.impuestos }}
                                        </td>
                                        <td>
                                          Porcentaje:
                                          {{ ivafacturacion.porcentaje }}
                                        </td>
                                        <td>Tipo: {{ ivafacturacion.tipo }}</td>
                                      </tr>
                                      <tr>
                                        <td><strong>IIBB</strong></td>
                                        <td>
                                          Impuestos:
                                          {{ iibbfacturacion.impuestos }}
                                        </td>
                                        <td>
                                          Porcentaje:
                                          {{ iibbfacturacion.porcentaje }}
                                        </td>
                                        <td>
                                          Tipo: {{ iibbfacturacion.impuestos }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <!-- cuenta -->
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                  <table class="table">
                                    <tbody
                                      v-for="item in cuenta"
                                      :key="item.id"
                                      align="left"
                                    >
                                      <tr>
                                        <td>
                                          <strong> Cuenta</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="item.cuenta"
                                          ></p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong> CBU</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="item.cbu"
                                          ></p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong> CVU</strong>
                                          <p
                                            class="font-weight-light"
                                            v-text="item.cvu"
                                          ></p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="facturacion = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <DialogContactos ref="dialogContactos" />
    <ModalEnvios ref="modalEnvios" />
  </div>
</template>
<script>
import { Clientes } from "../../services/clientes.js";
import { Impuestos } from "../../services/impuestos.js";
import { mapState, mapActions } from "vuex";
import DialogContactos from "./Clientes/DialogContactos.vue";
import ModalEnvios from "./Clientes/ModalEnvios.vue";

export default {
  components: { DialogContactos, ModalEnvios },
  data: () => ({
    cargando: false,
    dialog: false,
    dialogDelete: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    resEdit: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,

    // informacion de facturacion
    facturacion: false,
    // Filter models.
    headers: [
      { text: "Cliente", value: "cliente" },
      { text: "Razon social", value: "razon_social" },
      { text: "Nombre fantasia", value: "nombre_fantasia" },
      { text: "Retiros automáticos", value: "retiro_automatico" },
      { text: "Acciones", value: "actions", sortable: false, width: "20%" }, // width: "20%" para que entre en linea los botones en laptop device
    ],

    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    desserts: [],
    //Para Select de Split Clientes Cuentas
    clientes: [],
    cliente_vinculo: "",
    cliente_vinculoedit: "",
    datosClienteSplit: "",
    cliente_cliente: "",
    cliente_id: "",
    bandera: false,

    // nuevo cliente
    createCliente: false,
    cuit: "",
    razon_social: "",
    cliente: "",
    nombre_fantasia: "",
    personeria: "",
    selectivaiva: "",
    email: "",
    retiro_automatico: false,
    split_cuentas: false,
    sujeto_retencion: false,
    formulario_8125: false,
    ReporteItem: ["Reportes por Cliente", "Reportes por Cuenta"],
    Reporte_Cliente_Cuenta: "Reportes por Cliente",
    reportes_por_cliente: true,
    reportes_por_cuenta: false,

    /* FIXME estos datos deben ser los de la base de datos*/
    iva: [],
    iibb: [],
    selectivaiibb: null,
    // campos a actualizar
    cuitedit: "",
    razon_socialedit: "",
    clienteedit: "",
    nombre_fantasiaedit: "",
    email_edit: "",
    iva_idedit: {},
    iibb_idedit: {},
    retiro_automatico_edit: "",
    split_cuentas_edit: "",
    sujeto_retencion_edit: "",
    formulario_8125_edit: "",
    Reporte_Cliente_Cuenta_edit: "",
    iva1: "",
    iibb1: "",

    //Campos para actualizar cliente 2
    cuitedit2: "",
    razon_socialedit2: "",
    clienteedit2: "",
    nombre_fantasiaedit2: "",
    email_edit2: "",

    retiro_automatico_edit2: "",
    split_cuentas_edit2: "",
    sujeto_retencion_edit2: "",
    formulario_8125_edit2: "",
    Reporte_Cliente_Cuenta_edit2: "",

    cliente_vinculo2: "",
    cliente_vinculoedit2: "",
    datosClienteSplit2: "",
    cliente_cliente2: "",
    cliente_id2: "",
    cliente2Vinculado: "",

    datosClienteEdit: null,
    datosClienteEdit2: null,

    // Filtro
    clienteRSNF: "",

    // validaciones
    valid: false,
    namecuit: [
      (v) => !!v || "CUIT es requerido",
      (v) => (v && v.length <= 11) || "Cuit no debe superar los 11 caracteres",
      (v) => /^[0-9]+$/.test(v) || "El campo cuit es númerico",
    ],
    namerazon_social: [
      (v) => !!v || "Razon social es requerido",
      (v) =>
        (v && v.length <= 250) ||
        "El campo razon social no debe superar los 30 caracteres",
    ],
    namecliente: [
      (v) => !!v || "EL campo cliente es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo cliente no debe superar los 30 caracteres",
    ],
    namenombre_fantasia: [
      (v) => !!v || "EL campo Nombre fantasia es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo  Nombre fantasia no debe superar los 30 caracteres",
    ],
    personeriaedit: null,
    items: [
      { id: "F", personeria: "Fisica" },
      { id: "J", personeria: "Juridica" },
    ],
    nameemail: [
      (v) => !!v || "EL campo Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Email no es valido",
    ],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    clienteRSNF() {
      this.getdatos();
    },
  },

  created() {
    this.getdatos();
    this.limpiar_campos();
    this.getCliente();
    this.getImpuestosIva();
    this.getImpuestosIIBB();
  },
  computed: {
    ...mapState("facturacion", [
      "data",
      "ivafacturacion",
      "iibbfacturacion",
      "cuenta",
    ]),

    //Para select de Editar Cliente
    filteredClientes() {
      // Filtra los clientes excluyendo el cliente actual si su id es diferente a cliente_vinculo
      return this.clientes.filter(
        (cliente) =>
          // Incluye al cliente vinculado o al cliente con split igual a 0, pero excluye al cliente actual
          (cliente.id === this.cliente_vinculo ||
            cliente.clientes_splits_id === 0) &&
          cliente.id !== this.cliente_id
      );
    },
    //Para Select de Nuevo Cliente
    filteredClientesSplit0() {
      // Filtra los clientes para mostrar solo aquellos con clientes_splits_id igual a 0
      return this.clientes.filter(
        (cliente) => cliente.clientes_splits_id === 0
      );
    },

    clienteVinculoRules() {
      return this.split_cuentas ? [(v) => !!v || "Cliente es requerido"] : [];
    },
    clienteVinculoRules2() {
      return this.split_cuentas_edit
        ? [(v) => !!v || "Cliente es requerido"]
        : [];
    },
  },
  methods: {
    //--------MODAL ENVIOS-------
    showEnviosCliente(item) {
      this.$refs.modalEnvios.$emit("showModalEnvios", item);
    },
    //MODAL CONTACTOS DEL CLIENTE
    showDialogContactos(item) {
      this.$refs.dialogContactos.$emit("showDialogContactos", item);
    },
    //informacion de facturacion de clientes
    ...mapActions("facturacion", ["fetchFacturacion"]),
    //listar clientes
    getdatos: function() {
      const params = {
        CargarImpuestos: true,
        CargarCuentas: true,
        CargarRubros: true,
        CargarEnvio: true,
        Number: this.pagination.page,
        Size: this.rowsPerPage,
      };

      if (this.clienteRSNF) {
        params.BusquedaClienteRSNF = this.clienteRSNF;
      }

      this.cargando = true;

      Clientes.getClientes(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.desserts = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    //Listar todos los clientes sin paginacion para select
    getCliente() {
      const params = {
        CargarImpuestos: true,
        CargarCuentas: true,
        CargarRubros: true,
        CargarEnvio: true,
      };

      Clientes.getClientes(params)
        .then((response) => {
          if (response.data.data == null) {
            this.clientes = [];
          } else {
            this.clientes = response.data.data;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },

    /** Obtiene impuestos y filtra por IVA */
    getImpuestosIva() {
      const params = {
        Number: 1,
        Size: 10,
      };
      Impuestos.getImpuestos(params)
        .then((response) => {
          let regex = new RegExp("iv", "i");
          this.iva = response.data.data.filter((imp) =>
            regex.test(imp.impuesto)
          );
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },
    /** Obtiene impuestos y filtra por IIBB */
    getImpuestosIIBB() {
      const params = {
        Number: 1,
        Size: 10,
      };
      Impuestos.getImpuestos(params)
        .then((response) => {
          let regex = new RegExp("iibb", "i");
          this.iibb = response.data.data.filter((imp) =>
            regex.test(imp.impuesto)
          );
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },

    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },
    getColor(value) {
      if (value == true) return "#43A047";
      else return "#EF5350";
    },
    retiros_automaticos(value) {
      if (value === true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    },

    //Crear Split
    async crearSplit() {
      let split_id;
      try {
        const response = await Clientes.crearClienteSplit();
        split_id = response.data.data;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const data = error.response.data;
          this.$toastr.e(data.message);
        }
      }
      return split_id;
    },

    //Eliminar Splits, Este elimina de los clientes que tengan el valor que mando de id split y lo borra de la tabla de splits tambien
    async eliminarsplits(split_id) {
      try {
        await Clientes.deleteClienteSplit({ Id: split_id });
        this.$toastr.s("Los Clientes se desvincularon correctamente");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const data = error.response.data;
          this.$toastr.e(data.message);
        }
      }
    },

    // Guardar nuevo cliente
    async guardar_cliente() {
      let split_id = 0;
      if (this.split_cuentas) {
        split_id = await this.crearSplit();
      }
      var payload = {
        cuit: this.cuit,
        razon_social: this.razon_social,
        cliente: this.cliente,
        email: this.email,
        nombre_fantasia: this.nombre_fantasia,
        iva_id: this.selectivaiva,
        iibb_id: this.selectivaiibb,
        personeria: this.personeria,
        retiro_automatico: this.retiro_automatico,
        sujeto_retencion: this.sujeto_retencion,
        formulario_8125: this.formulario_8125,
        reportes_por_cuenta: this.reportes_por_cuenta,
        reportes_por_cliente: this.reportes_por_cliente,
      };

      await this.validate();
      if (this.res) {
        this.procensando_datos = true;
        //Para que cree el split para el cliente2
        if (this.split_cuentas && this.cliente_vinculo !== 0) {
          payload.clientes_splits_id = split_id;
          //Vincular Nuevo Cliente con el Cliente 2 ya existente para hacer la vinculacion
          await this.actualizarCliente2Split(split_id);
        }
        this.error = false;

        await Clientes.nuevo_cliente(payload)
          .then(() => {
            this.$toastr.s(
              `El cliente ${payload.cliente} se agrego correctamente`
            );
            this.getdatos();
            this.createCliente = false;
            this.reset();
            this.limpiar_campos();
          })
          .catch((error) => {
            if (error.response.status === 400) {
              var data = error.response.data;
              this.$toastr.e(data.message);
            }
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
      this.getCliente();
    },

    //Funcion para actualizar el cliente 2 para vincular
    async actualizarCliente2Split(split) {
      //Le mando el cliente vinculo osea el valor del id del cliente para vincular
      let datos = { id: this.cliente_vinculo };
      //Consulto datos del Cliente2 por split
      await this.consultar_cliente2(datos);

      let index_personeria = this.personeriaedit2;
      if (typeof this.personeriaedit2 === "object")
        index_personeria = this.personeriaedit2.id;

      let index_iva = this.iva_idedit2;
      if (typeof this.iva_idedit2 === "object") index_iva = this.iva_idedit2.id;

      let index_iibb = this.iibb_idedit2;
      if (typeof this.iibb_idedit2 === "object")
        index_iibb = this.iibb_idedit2.id;

      var payload = {
        ...this.datosClienteEdit2,
        id: this.cliente_id2,
        cuit: this.cuitedit2,
        razon_social: this.razon_socialedit2,
        email: this.email_edit2,
        cliente: this.clienteedit2,
        nombre_fantasia: this.nombre_fantasiaedit2,
        iva_id: index_iva,
        iibb_id: index_iibb,
        personeria: index_personeria,
        retiro_automatico: this.retiro_automatico_edit2,
        sujeto_retencion: this.sujeto_retencion_edit2,
        formulario_8125: this.formulario_8125_edit2,
        reportes_por_cliente: this.reportes_por_cliente2,
        reportes_por_cuenta: this.reportes_por_cuenta2,
        clientes_splits_id: split,
      };

      await Clientes.actualizar_cliente(payload)
        .then(() => {
          this.$toastr.s(
            `El cliente ${this.clienteedit2} se actualizó correctamente`
          );
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
      // cliente2 actualizado
    },

    // dialog para crear nuevo cliente
    abrir_create_cliente() {
      this.createCliente = true;
      this.reportes_por_cliente = true;
      this.reportes_por_cuenta = false;
    },

    cerrar_create_cliente() {
      this.createCliente = false;
      this.reset();
      this.limpiar_campos();
    },

    //Consultar los datos del cliente a modificar al hacer click en el BOTON DE EDITAR
    editItem(item) {
      this.consultar_cliente(item, false);
      this.bandera = false;
      this.dialog = true;
    },

    //CONFIRMAR actualizar los datos del cliente y guara cliente 1
    async actualizar() {
      this.procensando_datos = true;
      let split_id;

      // Si tiene split
      if (this.split_cuentas_edit) {
        // Verificar si el split actual es igual al nuevo
        if (this.cliente_vinculo !== this.cliente2Vinculado) {
          // Si hay un cambio en el split, proceder con la actualización
          if (this.datosClienteEdit.clientes_splits_id === 0) {
            // Crear un nuevo split si no existe uno previo
            split_id = await this.crearSplit();
          } else {
            // Eliminar el split existente y crear uno nuevo
            await this.eliminarsplits(this.datosClienteEdit.clientes_splits_id);
            split_id = await this.crearSplit();
          }

          // Llamar a la función para manejar la actualización
          await this.actualizarClienteConPayload(split_id);
        } else {
          await this.actualizarClienteConPayload();

          // Si no hay cambios, cerrar el diálogo y salir
          this.close();
        }
      }
      // Si no tiene split
      else {
        await this.actualizarClienteConPayload();
        // Verificar si hay un split existente
        // if (this.datosClienteEdit.clientes_splits_id !== 0) {
        //   // Si hay un split existente, eliminarlo

        //   await this.eliminarsplits(this.datosClienteEdit.clientes_splits_id);
        // }

        // Cerrar el diálogo si no hay split o si se ha eliminado
        this.close();
      }
      this.procensando_datos = false;

      // Actualizar la información del cliente 1 después de cualquier cambio
      this.getCliente();
      this.close();
    },

    // Nueva función para manejar la actualización con payload
    async actualizarClienteConPayload(split_id) {
      // Crear el payload
      var payload = {
        // ...this.datosClienteEdit,

        id: this.cliente_id,
        cuit: this.cuitedit,
        razon_social: this.razon_socialedit,
        email: this.email_edit,
        cliente: this.clienteedit,
        nombre_fantasia: this.nombre_fantasiaedit,

        personeria: this.personeriaedit?.id
          ? this.personeriaedit.id
          : this.personeriaedit,
        retiro_automatico: this.retiro_automatico_edit,
        sujeto_retencion: this.sujeto_retencion_edit,
        formulario_8125: this.formulario_8125_edit,
        reportes_por_cliente: this.reportes_por_cliente,
        reportes_por_cuenta: this.reportes_por_cuenta,
        iva_id: this.iva_idedit?.id ? this.iva_idedit.id : this.iva_idedit,
        iibb_id: this.iibb_idedit?.id ? this.iibb_idedit.id : this.iibb_idedit,
      };

      await this.validateEdit();

      if (this.resEdit) {
        // Actualizar cliente 2 con el nuevo split
        // if (split_id) {
        //   payload.clientes_splits_id = split_id;
        //   await this.actualizarCliente2Split(split_id);
        // } else {
        //   payload.clientes_splits_id = this.cliente_vinculoedit;
        // }
        this.error = false;

        try {
          await Clientes.actualizar_cliente(payload);
          this.dialog = false;
          this.$toastr.s(
            `El Cliente ${this.datosClienteEdit.cliente} se actualizó Correctamente`
          );
          this.getdatos();
        } catch (error) {
          this.errored = true;
          this.error = true;
          this.$toastr.e(error.response.data.message);
        }
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envío");
      }
    },

    close() {
      this.dialog = false;
      this.resetEdit();
      this.limpiar_campos();
      this.limpiar_campos2();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // reset de validaciones de campos
    reset() {
      this.$refs.form.reset();
    },

    resetEdit() {
      this.$refs.formEdit.reset();
    },

    // validar formulario actualizar
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    validateEdit() {
      this.$refs.formEdit.validate();
      this.resEdit = this.$refs.formEdit.validate();
    },

    //consultar cliente por id
    async consultar_cliente(item) {
      var data = new FormData();
      this.procensando_datos = true;
      data.append("id", item.id);
      data.append("CargarImpuestos", true);
      data.append("CargarTiposPago", true);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await Clientes.getClienteID(data, config)
        .then(async ({ data }) => {
          // Agregamos 'async' aquí
          this.datosClienteEdit = data;
          this.cliente_cliente =
            "¿ Desea eliminar el cliente " + data.cliente + "?";
          this.cliente_id = data.id;
          this.cuitedit = data.cuit;
          this.razon_socialedit = data.razon_social;
          this.clienteedit = data.cliente;

          this.email_edit = data.email;
          this.nombre_fantasiaedit = data.nombre_fantasia;
          var impRes = this.filtrarImpuestos(data.impuestos);
          this.iva_idedit = impRes["iva"][0];
          this.iibb_idedit = impRes["iibb"][0];
          this.retiro_automatico_edit = data.retiro_automatico;
          // this.cliente_vinculoedit = data.clientes_splits_id;
          this.sujeto_retencion_edit = data.sujeto_retencion;
          this.formulario_8125_edit = data.formulario_8125;
          this.reportes_por_cliente = data.reportes_por_cliente;
          this.reportes_por_cuenta = data.reportes_por_cuenta;

          // if (this.cliente_vinculoedit !== 0) {
          //   this.split_cuentas_edit = true;
          //   //Busco al cliente 2 con el split del cliente 1
          //   const clientes = await this.consultar_cliente2Split(
          //     this.cliente_vinculoedit
          //   );
          //   //guardo el id del cliente 2 en clienteVinculado... osea clientes en realidad es solo un cliente
          //   const clienteVinculado = clientes.find(
          //     (cliente) => cliente.id !== this.cliente_id
          //   );
          //   //Asigno el cliente que es distito al id del que estoy
          //   if (clienteVinculado) {
          //     //Guardo el cliente.id del cliente 2
          //     this.cliente_vinculo = clienteVinculado.id;
          //     this.cliente2Vinculado = clienteVinculado.id;
          //   } else {
          //     this.cliente_vinculo = 0; // Manejar el caso donde no hay cliente vinculado
          //   }
          // } else {
          //   this.split_cuentas_edit = false;
          // }

          if (this.reportes_por_cliente) {
            this.Reporte_Cliente_Cuenta_edit = "Reportes por Cliente";
          } else if (this.reportes_por_cuenta) {
            this.Reporte_Cliente_Cuenta_edit = "Reportes por Cuenta";
          } else {
            this.Reporte_Cliente_Cuenta_edit = "No tiene Reporte asignado!";
          }

          if (data.personeria == "F") {
            this.personeriaedit = { id: "F", personeria: "Fisica" };
          } else {
            this.personeriaedit = { id: "J", personeria: "Juridica" };
          }
        })
        .catch((error) => {
          var dataError = error.response.data;
          this.error = true;
          this.msg = dataError.message;
          this.$toastr.e(dataError.message);
        })
        .finally(() => {
          this.procensando_datos = false;
          this.personeriaedit2 = this.personeriaedit;
          this.iva1 = this.iva_idedit;
          this.iibb1 = this.iibb_idedit;
        });
    },

    async consultar_cliente2(item) {
      var data = new FormData();
      //si es un split_id

      //Traigo los datos para el Cliente 1 para Ver en el modal
      this.procensando_datos = true;
      data.append("id", item.id);
      data.append("CargarImpuestos", true);
      data.append("CargarTiposPago", true);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await Clientes.getClienteID(data, config)
        .then(({ data }) => {
          this.datosClienteEdit2 = data;
          this.cliente_cliente2 =
            "¿ Desea eliminar el cliente " + data.cliente + "?";
          this.cliente_id2 = data.id;
          //cargar los datos en el modal
          this.cuitedit2 = data.cuit;
          this.razon_socialedit2 = data.razon_social;
          this.clienteedit2 = data.cliente;
          this.email_edit2 = data.email;
          this.nombre_fantasiaedit2 = data.nombre_fantasia;
          var impRes = this.filtrarImpuestos(data.impuestos);
          this.iva_idedit2 = impRes["iva"][0];
          this.iibb_idedit2 = impRes["iibb"][0];
          this.retiro_automatico_edit2 = data.retiro_automatico;

          this.cliente_vinculoedit2 = data.clientes_splits_id;

          this.sujeto_retencion_edit2 = data.sujeto_retencion;
          this.formulario_8125_edit2 = data.formulario_8125;
          this.reportes_por_cliente2 = data.reportes_por_cliente;
          this.reportes_por_cuenta2 = data.reportes_por_cuenta;

          // Para Modal de split en editar
          if (this.cliente_vinculo2 !== 0) {
            this.split_cuentas_edit2 = true;
          } else {
            this.split_cuentas_edit2 = false;
            this.cliente_vinculo2 = 0;
          }

          //Para asignar el valor al select con su valor
          if (this.reportes_por_cliente2) {
            this.Reporte_Cliente_Cuenta_edit2 = "Reportes por Cliente";
          } else if (this.reportes_por_cuenta2) {
            this.Reporte_Cliente_Cuenta_edit2 = "Reportes por Cuenta";
          } else {
            this.Reporte_Cliente_Cuenta_edit2 = "No tiene Reporte asignado!";
          }

          if (data.personeria == "F") {
            this.personeriaedit2 = { id: "F", personeria: "Fisica" };
          } else {
            this.personeriaedit2 = { id: "J", personeria: "Juridica" };
          }
        })
        .catch((error) => {
          var dataError = error.response.data;
          this.error = true;
          this.msg = dataError.message;
          this.$toastr.e(dataError.message);
        })
        .finally(
          () => (
            (this.procensando_datos = false),
            (this.personeriaedit3 = this.personeriaedit2)
          )
        );
    },

    async consultar_cliente2Split(item) {
      try {
        const response = await Clientes.getClientes({ ClienteSplit: item });
        this.procensando_datos = false;
        return response.data.data; // Retornamos el id del cliente
      } catch (error) {
        this.procensando_datos = false;
        var dataError = error.response.data;
        this.error = true;
        this.msg = dataError.message;
        this.$toastr.e(dataError.message);
        throw error; // Lanza el error para que pueda ser manejado por el caller
      }
    },

    // error de api , esta funcion vuelve a recargar la pagina
    intento() {
      this.$router.go();
    },
    // limpiar_campos
    limpiar_campos() {
      this.cuit = "";
      this.razon_social = "";
      this.cliente = "";
      this.nombre_fantasia = "";
      this.iva_id = "";
      this.selectivaiva = "";
      this.selectivaiibb = "";
      this.iibb_id = "";
      this.personeria = "";
      this.email = "";
      this.cliente_vinculo = "";
      this.cliente2Vinculado = "";
      this.Reporte_Cliente_Cuenta = "Reportes por Cliente";
      this.reportes_por_cliente = true;
      this.reportes_por_cuenta = false;
    },

    limpiar_campos2() {
      this.cuit2 = "";
      this.razon_social2 = "";
      this.cliente2 = "";
      this.nombre_fantasia2 = "";
      this.iva_id2 = "";
      this.selectivaiva2 = "";
      this.selectivaiibb2 = "";
      this.iibb_id2 = "";
      this.personeria2 = "";
      this.email2 = "";
      this.cliente_vinculo2 = "";
      this.Reporte_Cliente_Cuenta2 = "Reportes por Cliente";
      this.reportes_por_cliente2 = true;
      this.reportes_por_cuenta2 = false;
    },

    // ir a vista con las cuentas del cliente
    cargar_cuentas_cliente(item) {
      this.$router.push({
        name: "IndexClienteCuentas",
        params: { cliente_id: item.id },
      });
    },

    // ir a vista con las retenciones del cliente
    navigateToRetencion(id) {
      this.$router.push({
        name: "RetencionesClientes",
        params: { cliente_id: id },
      });
    },

    facturaciones(item) {
      this.$store.commit("facturacion/setIdCliente", item.id);
      this.fetchFacturacion();
      this.facturacion = true;
    },
    filtrarImpuestos(impuestos) {
      var impRes = new Array();
      let regexIva = new RegExp("iv", "i");
      let iva = impuestos.filter((imp) => regexIva.test(imp.impuesto));
      impRes["iva"] = iva;
      let regexIB = new RegExp("iibb", "i");
      let iibb = impuestos.filter((imp) => regexIB.test(imp.impuesto));
      impRes["iibb"] = iibb;
      return impRes;
    },

    ReporteSeleccionado(Reporte_Cliente_Cuenta) {
      // Reiniciar los valores
      this.reportes_por_cliente = false;
      this.reportes_por_cuenta = false;

      // Establecer el valor correspondiente en true
      if (Reporte_Cliente_Cuenta === "Reportes por Cliente") {
        this.reportes_por_cliente = true;
      } else if (Reporte_Cliente_Cuenta === "Reportes por Cuenta") {
        this.reportes_por_cuenta = true;
      }
    },
  },
};
</script>
<style scoped>
/* efecto tabla de cliente */
/* .trow {
  transition: transform 0.3s;
} */
/* .trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */
a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}

.theme--light.v-subheader {
  color: rgb(27 15 216 / 89%);
}
</style>
